import React from 'react'
import { Row, Col } from 'react-grid-system'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Categories from '../components/categories'
import PostExcerpt from '../components/post-excerpt'
import SEO from '../components/seo'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <Categories />

    <Row style={{
      padding: `1rem 3rem`,
      paddingTop: 0,
    }}>
      <Col sm={6}>
        <h2>New posts</h2>

        {data.allMarkdownRemark.edges.map(({ node }) => (
            <div key={node.id}>

              <PostExcerpt
              title={node.frontmatter.title}
              date={node.frontmatter.date}
              category={node.frontmatter.category}
              excerpt={node.excerpt}
              slug={node.fields.slug} />

            </div>
          ))}
      </Col>
    </Row>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    },
    allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category,
            published
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
