import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Category from './category'

const Categories = ({ data }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { id: { ne: null } } }
          sort: { fields: [frontmatter___id], order: ASC }
        ) {
          totalCount
          edges {
            node {
              frontmatter {
                id,
                name,
                gradient,
                icon,
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div style={{
        background: '#121212',
        color: '#fff',
        padding: `3rem`,
        margin: '50px 0'
      }}>
        <h2>Categories</h2>
        <div style={{
          display: 'flex'
        }}>

          {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.frontmatter.id}>

                <Category icon={require('../images/' + node.frontmatter.icon)} name={node.frontmatter.name} to={node.frontmatter.url} gradient={node.frontmatter.gradient} />

              </div>
            ))}

        </div>
      </div>
    )}
  />
)

export default Categories
