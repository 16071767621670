import PropTypes from 'prop-types'
import React from 'react'

import {Link} from 'gatsby'

const PostExcerpt = props => (
  <Link to={props.slug} style={{
    textDecoration: 'none',
    color: '#000'
  }} >
    <div style={{
      padding: '30px',
      border: '1px solid lightgrey',
      borderRadius: 8,
      marginBottom: 30
    }}>
      <h4 style={{
        marginBottom: 5
      }}>{props.title}</h4>
      <small style={{
        marginBottom: 20,
        display: 'block'
      }}>{props.category.charAt(0).toUpperCase() + props.category.slice(1)} - {props.date}</small>

      <p style={{
        marginBottom: 0
      }}>{props.excerpt}</p>
    </div>
  </Link>
)

PostExcerpt.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  category: PropTypes.string
}

PostExcerpt.defaultProps = {
  title: '',
  date: '',
  excerpt: '',
  category: ''
}

export default PostExcerpt
