import React from 'react'
import {Link} from 'gatsby'

const Category = props => (
  <Link to={'/category/' + props.to} style={{
    textDecoration: 'none'
  }}>
    <div className="category" style={{
      padding: 50,
      color: '#fff',
      marginRight: 20,
      width: 240,
      textAlign: 'center',
      borderRadius: 10,
      backgroundImage: props.gradient
    }}>
      <img style={{
        maxHeight: 55,
        marginBottom: 20,
        filter: 'invert(100%)'
      }} src={props.icon} alt='Icon' />

      <h3 style={{
        fontFamily: '',
        fontWeight: 400,
        marginBottom: 15,
        fontSize: '1rem'
      }}>{props.name}</h3>
    </div>
  </Link>
)

export default Category
